<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'">
    <Snackbar ref="snackbar" />

    <!-- <DialogAttachment
      :dialog_attachment="dialog.attachment"
      :state="process.state"
      :company_id="form.id"
      @input="data_attachment"
      @file_response="response_attachment"
      @close="closeDialog"
    /> -->

    <div class="mt-5">
      <div class="title black--text mb-2">Update Trainer</div>
      <Breadcrumbs :items="breadcrumbs" />
    </div>

    <v-card outlined class="rounded-lg mb-5" v-if="!process.run">
      <v-card-text>
        <ValidationObserver ref="observer">
          <v-form>
            <p class="text-h6">Profil Trainer</p>
            <ValidationProvider ref="fullname" name="Fullname" rules="required" v-slot="{ errors }">
              <v-text-field
                outlined
                dense
                flat
                v-model="form.fullname"
                :error-messages="errors"
                label="Trainer Name"
                color="#F05326">
              </v-text-field>
            </ValidationProvider>
            <ValidationProvider ref="email" name="Email Address" rules="required|email" v-slot="{ errors }">
              <v-text-field
                outlined
                dense
                flat
                v-model="form.email"
                :error-messages="errors"
                label="Email Address"
                color="#F05326">
              </v-text-field>
            </ValidationProvider>
            <ValidationProvider ref="phone_number" name="Phone Number" rules="required" v-slot="{ errors }">
              <v-text-field
                outlined
                dense
                flat
                v-model="form.phone_number"
                :error-messages="errors"
                label="Phone Number"
                color="#F05326">
              </v-text-field>
            </ValidationProvider>
            <v-col cols="12">
              <p class="mb-0">Gender</p>
              <v-radio-group row class="mt-2" hide-details="auto" v-model="form.gender.value">
                <v-radio color="primary" :value="1">
                  <template v-slot:label>
                    <div class="black--text">Male</div>
                  </template>
                </v-radio>
                <v-radio color="primary" :value="0">
                  <template v-slot:label>
                    <div class="black--text">Female</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <p class="mb-0">Trainer Status</p>
              <v-radio-group row class="mt-2" hide-details="auto" v-model="form.status">
                <v-radio color="primary" value="active">
                  <template v-slot:label>
                    <div class="black--text">Active</div>
                  </template>
                </v-radio>
                <v-radio color="primary" value="inactive">
                  <template v-slot:label>
                    <div class="black--text">Inactive</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <!-- <v-textarea
              outlined
              dense
              flat
              v-model="form.description"
              label="Deskripsi Trainer"
              rows="3"
              color="#F05326"
            ></v-textarea> -->
            <!-- <p class="text-h6">Input Program Trainer</p>
            <v-row align="center">
              <v-col>
                <v-select
                  v-model="form.programs"
                  :items="programOptions"
                  chips
                  multiple
                  outlined
                  color="#F05326"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip>
                      <span>{{ item }}</span>
                      <v-icon small :key="index" @click="removeProgram(index)"
                        >mdi-close</v-icon
                      >
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
            </v-row> -->
            <!-- <p class="text-h6">Setting Trainer</p>
            <div class="text-subtitle-2">
              <span> Aktifkan Notifikasi</span>
            </div>
            <v-radio-group v-model="form.setting.enable_notification">
              <v-radio :value="true" color="#F05326">
                <span slot="label" class="color--text">Ya</span>
              </v-radio>
              <v-radio :value="false" color="#F05326">
                <span slot="label" class="color--text">Tidak</span>
              </v-radio>
            </v-radio-group>
            <div class="text-subtitle-2">
              <span> Aktifkan Update Profile User (one time)</span>
            </div>
            <v-radio-group v-model="form.setting.one_time_update_profile">
              <v-radio :value="true" color="#F05326">
                <span slot="label" class="color--text">Ya</span>
              </v-radio>
              <v-radio :value="false" color="#F05326">
                <span slot="label" class="color--text">Tidak</span>
              </v-radio>
            </v-radio-group> -->
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions class="pb-4 px-4">
        <v-spacer></v-spacer>
        <v-btn
          depressed
          width="120"
          height="40"
          color="grey lighten-3"
          class="text-capitalize"
          :to="`/company/trainer-list/${$route.params.company_id}`"
        >
          Cancel
        </v-btn>
        <v-btn
          depressed
          width="120"
          height="40"
          color="primary"
          class="text-capitalize"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { get, post, put, destroy } from "@/service/Axios";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Snackbar from "@/components/Snackbar.vue";
import DialogAttachment from "../DialogAttacment.vue";
export default {
  components: { 
    Breadcrumbs, 
    Snackbar, 
    DialogAttachment 
  },
  data() {
    return {
      programOptions: ["Program 1 ", "Program 2 ", "Program 3 ", "Program 4 "],
      breadcrumbs: [
        {
          text: "List Trainer",
          disabled: false,
          href: `/company/trainer-list/${this.$route.params.company_id}`,
        },
        {
          text: "Update Trainer",
          disabled: true,
          href: "#",
        },
      ],
      items: {},
      member: {},
      form: {},
      dialog: {
        attachment: false,
      },
      process: {
        run: false,
        state: "",
      },
    };
  },
  created() {
    this.getDetail();
  },
  mounted() {},
  methods: {
    removeProgram(index) {
      this.form.programs.splice(index, 1);
    },
    async getDetail() {
      let _self = this;
      _self.process.run = true;

      await get(`trainer/v1/detail/${this.$route.params.id}`).then(
        (response) => {
          let res = response.data;
          this.form = res.data;
          this.process.run = false;
          this.$store.state.process.run = false;
        }
      );
    },
    async save() {

      const isValid = await this.$refs.observer.validate()

      if(isValid) {
        let data = {
          fullname: this.form.fullname,
          email: this.form.email,
          phone_number: this.form.phone_number,
          gender: this.form.gender.value,
          status: this.form.status,
          // description: this.form.description,
          // additional_fields: {
          //   member_redeems: this.form.additional_fields.member_redeems
          //     ? this.form.additional_fields.member_redeems
          //     : [],
          //   members: this.form.additional_fields.members
          //     ? this.form.additional_fields.members
          //     : [],
          //   resellers: this.form.additional_fields.resellers
          //     ? this.form.additional_fields.resellers
          //     : [],
          // },
          // setting: {
          //   enable_notification: this.form.setting.enable_notification,
          //   one_time_update_profile: this.form.setting.one_time_update_profile,
          // },
          // programs: this.programOptions,
        };
        this.process.run = true;
        put(`trainer/v1/update/${this.$route.params.id}`, {
          data: data,
        })
          .then((response) => {
            let res = response.data;
            if (res.status) {
              this.$refs.snackbar.open("#4CAF50", `Update Trainer Successfully`);
              setTimeout(() => {
                this.$router.push(`/company/trainer-list/${this.$route.params.company_id}`,);
              }, 1000);
            } else {
              this.$refs.snackbar.open("#D32F2F", `Update Trainer Failed`);
            }
            this.process.run = false;
          })
          .catch((error) => {

            if (error.response) {
              this.process.run = false;
              if(Object.keys(error.response.data.validation).length > 0) {
                Object.keys(error.response.data.validation).forEach(item => {
                  this.$refs[item].applyResult({
                    errors: [error.response.data.validation[item]],
                    valid: false,
                    failedRules: {},
                  });
                })
              }else {
                this.$refs.snackbar.open("#e53935", error.response.data.message);
              }
            }

            this.process.run = false;
          });
        // this.saveProgramOptions();
      }

    },
    data_attachment(data) {
      console.log(data);
    },
    closeDialog () {
      this.dialog.attachment = false
    },
  },
};
</script>

<style>
.dropZone {
  width: 100%;
  min-height: 300px;
  height: auto;
  position: relative;
  border: 3px dashed #ddd;
}

.dropZone:hover {
  border: 2px solid #f05326;
}

.dropZone:hover .dropZone-title {
  color: #f05326;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
</style>
